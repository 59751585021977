import React, { useCallback, useEffect, useState } from "react";
import { AuthUser } from "../interfaces/AuthUser";
import { default as ApiService } from "../context/ApiServices";
import { IAgentCreate } from "../interfaces/Agent";
import { jwtDecode } from "jwt-decode";

const AuthContext = React.createContext<{
  actions: {
    login: (username: string, password: string) => Promise<any>;
    logout: () => void;
    createGuest: (
      cityId: string,
      businessName: string,
      voice: string,
      agents: IAgentCreate[]
    ) => Promise<any>;

    promoteUser: (username: string, password: string) => Promise<any>;
    forgotPassword: (email: string) => Promise<any>;
    resetPassword: (password: string, token: string) => Promise<any>;
    isLoggedIn: () => boolean;
    expireAt: (authToken: AuthUser | null | undefined) => number;
  };
  data: {
    authToken: AuthUser | null | undefined;
  };
}>({
  actions: {
    login: async () => undefined,
    logout: () => undefined,
    createGuest: async () => undefined,
    promoteUser: async () => undefined,
    forgotPassword: async () => undefined,
    resetPassword: async () => undefined,
    isLoggedIn: () => false,
    expireAt: (_) => -1,
  },
  data: {
    authToken: undefined,
  },
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export function AuthContextProvider(
  props: AuthContextProviderProps
): React.ReactElement {
  const { children } = props;
  const [authToken, setAuthToken] = useState<AuthUser | null>();

  useEffect(() => {
    const json = localStorage.getItem("user");
    if (json) setAuthToken(JSON.parse(json));
    else setAuthToken(null);
  }, []);

  const expireAt = useCallback((token: AuthUser | null | undefined) => {
    if (token) {
      const decoded = jwtDecode(token.access_token);
      if (decoded && decoded.exp) return decoded.exp * 1000;
    }
    return -1;
  }, []);

  const isLoggedIn = useCallback(() => {
    if (!authToken) return false;

    const exp = expireAt(authToken);
    if (exp < 0 || Date.now() >= exp) {
      return false;
    }
    return true;
  }, [authToken, expireAt]);

  const logout = useCallback(() => {
    setAuthToken(undefined);
    localStorage.removeItem("user");
  }, []);

  const login = useCallback(
    (username: string, password: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `/login`,
            {
              username,
              password,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(response);
        } catch (e) {
          logout();
          reject(e);
        }
      });
    },
    [logout]
  );

  const createGuest = useCallback(
    (
      cityId: string,
      businessName: string,
      voice: string,
      agents: IAgentCreate[]
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `/user?city=${cityId}`,
            {
              company_name: businessName,
              voice: voice,
              users: agents,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    []
  );

  const promoteUser = useCallback(
    (username: string, password: string) => {
      return new Promise(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = await ApiService.api.put(
            `promote_guest?username=${username}&password=${password}`,
            {},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${authToken?.access_token}`,
              },
            }
          );
          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const forgotPassword = useCallback((email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService.api.post(
          `forgot-password`,
          { email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        resolve(response);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const resetPassword = useCallback((password: string, token: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService.api.post(
          `reset-password`,
          { new_password: password, token },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        resolve(response);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        actions: {
          login,
          logout,
          createGuest,
          promoteUser,
          forgotPassword,
          resetPassword,
          isLoggedIn,
          expireAt,
        },
        data: {
          authToken,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
