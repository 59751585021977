import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Stack,
  TextField,
} from "@mui/material";
import { CardItem } from "../interfaces/CardItem";
import { useCallback } from "react";

export interface ModelCardProps extends CardProps {
  card: CardItem;
  onCardChange: (card: CardItem) => void;
  onCardRemove: () => void;
}

export function ModelCard(props: ModelCardProps) {
  const { card, onCardChange, onCardRemove, ...restProps } = props;
  const { title, body, sort_order } = card;

  const handleCardChange = useCallback(
    (field: keyof CardItem, value: string) => {
      const newCard: CardItem = { ...card };
      (newCard as any)[field] = value;
      onCardChange(newCard);
    },
    [card, onCardChange]
  );

  return (
    <Card
      {...restProps}
      sx={{
        backgroundColor: "#F8FAFE",
        ...props.sx,
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#7c7d7f" }} aria-label="recipe">
            {sort_order + 1}
          </Avatar>
        }
        title={
          <TextField
            sx={{ backgroundColor: "#FFF", width: "100%" }}
            variant="outlined"
            type="text"
            placeholder="Title"
            value={title}
            inputProps={{
              maxLength: 50,
            }}
            onChange={(e) => handleCardChange("title", e.target.value)}
            data-no-dnd="true"
          />
        }
      />
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <TextField
            sx={{ backgroundColor: "#FFF" }}
            placeholder="You are a virtual receptionist. Your job is to greet the caller, and provide assistance to them"
            multiline
            rows={10}
            value={body}
            onChange={(e) => handleCardChange("body", e.target.value)}
            data-no-dnd="true"
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => onCardRemove()} data-no-dnd="true">
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}
