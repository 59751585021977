export const Premium = (props: {
  color?: string;
  size?: number;
  strokeWidth?: number;
}) => {
  return (
    <svg
      width="8.4667mm"
      height="8.4667mm"
      version="1.1"
      viewBox="0 0 8.4667 8.4667"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-114.59 -94.88)">
        <g transform="matrix(.56444 0 0 .56444 101.49 107.08)">
          <rect
            x="25.685"
            y="-18.752"
            width="10.219"
            height="9.2222"
            ry=".99112"
            fill="#fff"
          />
          <path
            d="m23.196-14.112c0-4.1421 3.3579-7.5 7.5-7.5 4.1421 0 7.5 3.3579 7.5 7.5 0 4.1421-3.3579 7.5-7.5 7.5-4.1421 0-7.5-3.3579-7.5-7.5zm7.5-4c0.19386 0 0.37025 0.11206 0.45262 0.28755l0.96824 2.0628 2.1546 0.32898c0.1852 0.02828 0.3391 0.1578 0.3986 0.33552s0.0147 0.37379-0.1162 0.50793l-1.5738 1.613 0.37258 2.2838c0.0308 0.189-0.049 0.379-0.20549 0.4892-0.15653 0.1103-0.36229 0.1215-0.52987 0.0289l-1.9213-1.0621-1.9213 1.0621c-0.16757 0.0926-0.37334 0.0814-0.52987-0.0289-0.15653-0.1102-0.23633-0.3002-0.2055-0.4892l0.37259-2.2838-1.5738-1.613c-0.13088-0.13414-0.17576-0.33021-0.11626-0.50793 0.05951-0.17772 0.2134-0.30724 0.39866-0.33552l2.1546-0.32898 0.96823-2.0628c0.08237-0.17549 0.25876-0.28755 0.45262-0.28755z"
            clipRule="evenodd"
            fill="#5e6cb2"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
