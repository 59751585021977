import {
  Box,
  Link,
  Paper,
  Stack,
} from "@mui/material";
import React, {  } from "react";
import { theme } from "../../theme";
import { Logo } from "../../img/Logo";


interface PlainContainerProps {
  children: React.ReactElement;
}

export default function PlainContainer(props: PlainContainerProps) {
  const { children } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100dvh"
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <div className="chakra-image css-16duawu">
          <Link href="/"><Logo /></Link>
        </div>
        <Paper
          sx={{
            width: { xs: 340, sm: 400, md: 500, lg: 500 },
            padding: theme.spacing(2),
          }}
        >
          {children}
        </Paper>
      </Stack>
    </Box>
  );
}
