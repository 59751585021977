import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import AvailabilityAccordian from "./AvailabilityAccordian";
import {
  Agent,
  Availability,
  AvailabilityTime,
  DaysOfWeek,
} from "../interfaces/Agent";
import { Dayjs } from "dayjs";

interface TeamMemberProps {
  agent: Agent;
  availability: Availability;
  showRemove: boolean;
  expanded: boolean;
  onAgentChange: (_: Agent) => void;
  onAgentRemove: () => void;
  onAgentAvailabilityChange: (_: Availability) => void;
  onExpandChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export function TeamMember(props: TeamMemberProps) {
  const {
    agent,
    availability,
    showRemove,
    expanded,
    onAgentChange,
    onAgentRemove,
    onAgentAvailabilityChange,
    onExpandChange,
  } = props;
  const accordianRef = useRef<HTMLDivElement | null>();

  const handleAgentChange = useCallback(
    (field: keyof Agent, value: string | boolean) => {
      const newAgent: Agent = { ...agent };
      (newAgent as any)[field] = value;
      onAgentChange(newAgent);
    },
    [agent, onAgentChange]
  );

  const handleRemoveAgent = useCallback(() => {
    onAgentRemove();
  }, [onAgentRemove]);

  const handleAgentAvailbilityChange = useCallback(
    (day: DaysOfWeek, isStart: boolean, time?: Dayjs | null) => {
      const value = time?.format("HH:mm");
      let avail = undefined;
      if (availability) avail = availability![day];

      let newDay: AvailabilityTime | undefined = {
        start: isStart ? value : avail?.start,
        end: !isStart ? value : avail?.end,
      };
      if (!time && ((isStart && !avail?.end) || (!isStart && !avail?.start))) {
        //need to delete the record if we are deleting
        newDay = undefined;
      }

      const newAvilable: Availability = {
        ...availability,
        [day]: newDay,
      };

      onAgentAvailabilityChange(newAvilable);
    },
    [availability, onAgentAvailabilityChange]
  );

  const handleExpandChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    onExpandChange(event, expanded);

  };

  return (
    <Paper elevation={2} sx={{ width: "100%", backgroundColor: "#F8FAFE" }}>
      <div className="grid grid-cols-12 gap-4 p-1 md:p-4 text-white">
        <TextField
          className="w-full col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-4 order-1 md:order-1 lg:order-1 rounded-md !ring-transparent outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
          sx={{ background: "white" }}
          type="text"
          placeholder="First Name"
          value={agent.first_name || ""}
          onChange={(e) => handleAgentChange("first_name", e.target.value)}
          inputProps={{
            maxLength: 50,
          }}
        />
        <TextField
          className="w-full col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-4 order-2 md:order-2 lg:order-2 rounded-md !ring-transparent outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
          sx={{ background: "white" }}
          type="text"
          placeholder="Last Name"
          value={agent.last_name || ""}
          onChange={(e) => handleAgentChange("last_name", e.target.value)}
          inputProps={{
            maxLength: 50,
          }}
        />
        <TextField
          className="w-full col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-4 order-3 md:order-4 lg:order-4 rounded-md !ring-transparent outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
          sx={{ background: "white" }}
          type="tel"
          placeholder="Phone"
          value={agent.phone || ""}
          onChange={(e) => handleAgentChange("phone", e.target.value)}
          inputProps={{
            maxLength: 10,
          }}
        />
        <TextField
          className="w-full col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-4 order-4 md:order-5 lg:order-5 rounded-md !ring-transparent outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
          sx={{ background: "white" }}
          type="email"
          placeholder="Email (Add to receive voicemails)"
          value={agent.email || ""}
          onChange={(e) => handleAgentChange("email", e.target.value)}
          inputProps={{
            maxLength: 50,
          }}
        />

        <div className="w-full col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-4 order-5 md:order-3 lg:order-3">
          <div className="flex justify-between items-center w-full h-full">
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#DEDEDE",
                    }}
                    checked={agent.is_away}
                    onChange={(_, checked) => {
                      handleAgentChange("is_away", checked);
                    }}
                  />
                }
                label="Unavailable. Take a message"
              />
            </div>
            <div>
              {showRemove && (
                <IconButton
                  disabled={!showRemove}
                  onClick={(e) => handleRemoveAgent()}
                >
                  <Close />
                </IconButton>
              )}
            </div>
          </div>
        </div>

        <div className="w-full col-span-12 order-last rounded-md ring-transparent outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5">
          <Box ref={accordianRef}>
            <AvailabilityAccordian
              available={availability}
              expanded={expanded}
              onChange={handleExpandChange}
              onStartChange={(day, time) =>
                handleAgentAvailbilityChange(day, true, time)
              }
              onEndChange={(day, time) =>
                handleAgentAvailbilityChange(day, false, time)
              }
            />
          </Box>
        </div>
      </div>
    </Paper>
  );
}
