import { useContext, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ApiContext from "../context/ApiContext";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LoadingCircle } from "../img/LoadingCircle";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { showFriendlyErrorFromHTMLCode } from "../utils/utils";
interface IFormInput {
  phone: string;
}

const schema = yup
  .object({
    phone: yup
      .string()
      .required("Phone number is required.")
      .test((phone, ctx) => {
        if (phone.length < 9 || !isValidPhoneNumber(phone, "CA"))
          return ctx.createError({
            message: `${phone} is not a valid phone number`,
          });
        return true;
      }),
  })
  .required();

export default function OutboundCallForm() {
  const {
    actions: { requestOutboundCall },
  } = useContext(ApiContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    const { phone } = data;
    if (!phone || phone?.length <= 0) return;
    if(loading) return;
    setError(null);
    setLoading(true);
    try {
      await requestOutboundCall(phone);
    } catch (error) {
      setError(showFriendlyErrorFromHTMLCode(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form id="outbound-form" onSubmit={handleSubmit(onSubmit)}></form>
      <Accordion
        slotProps={{ transition: { unmountOnExit: true } }}
        disableGutters
        elevation={0}
        square
      >
        <AccordionSummary
          aria-controls="panel2-content"
          id="panel2-header"
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        >
          <Typography variant="h6" sx={{ margin: 0 }}>
            Outbound
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flext-start"
            spacing={2}
            sx={{ paddingLeft: 2 }}
          >
            <Grid item xs={8} sm={6} md={3}>
              <Controller
                control={control}
                name={`phone`}
                render={({ field }) => {
                  const e = errors.phone?.message || error;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== null}
                      helperText={e ? e.toString() : "　"}
                      sx={{ width: "100%" }}
                      type="text"
                      placeholder="Phone"
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <Button
                onClick={handleSubmit(onSubmit)}
                sx={{ marginTop: 1 }}
                variant="contained"
              >
                Call Me
                {loading && <LoadingCircle />}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
