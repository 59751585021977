import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import AuthContext from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import PlainContainer from "../components/container/PlainContainer";
import { showFriendlyErrorFromHTMLCode } from "../utils/utils";

interface IFormInput {
  email: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
  })
  .required();

export default function ForgotPasswordView() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const {
    actions: { forgotPassword },
  } = useContext(AuthContext);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email } = data;
    setError("");
    setSuccess("");
    setLoading(true);
    forgotPassword(email)
      .then((response) => {
        setError("");
        setSuccess(
          "If an account exists with this email, a password reset link was sent"
        );
      })
      .catch((error) => {
        setError(showFriendlyErrorFromHTMLCode(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PlainContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {success && <Alert severity="success">{success}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h3">Forgot Password</Typography>
          <Typography variant="body1">
            Please enter the email you use to sign in to OpenVoice
          </Typography>
          <Typography variant="h6">Email</Typography>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.email !== undefined}
                helperText={errors.email ? errors.email?.message : "　"}
                className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                type="text"
              />
            )}
          />

          <Button sx={{ width: "100%" }} variant="contained" type="submit">
            Reset Password {loading ? <LoadingCircle /> : <RightArrow />}
          </Button>
          <div className="grid text-sm text-muted-foreground">
            <p>
              Remember password? <Link href="/signin">Sign in</Link> instead
            </p>
          </div>
        </Stack>
      </form>
    </PlainContainer>
  );
}
