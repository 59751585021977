import { Box, Button, Stack } from "@mui/material";
import ApiContext from "../../context/ApiContext";
import { useContext, useRef } from "react";
import { TeamMember } from "../TeamMember";
import { Agent, Availability } from "../../interfaces/Agent";

export interface TeamAccordianSectionProps {
  expanded: boolean[];
  onChange: (
    index: number,
    event: React.SyntheticEvent,
    expanded: boolean
  ) => void;
}

export function TeamAccordianSection(props: TeamAccordianSectionProps) {
  const { expanded, onChange } = props;
  const {
    data: { agents, agentAvailabilty },
    actions: { setAgents, setAgentAvailablity },
  } = useContext(ApiContext);

  const handleAgentChange = (index: number, value: Agent) => {
    const newAgents = [...agents];
    newAgents[index] = value;
    setAgents(newAgents);
  };

  const handleRemoveAgent = (index: number) => {
    if (agents.length > 1) {
      const updatedAgents = [...agents];
      updatedAgents.splice(index, 1);
      setAgents(updatedAgents);
    }
  };

  const handleAgentAvailabilityChange = (
    index: number,
    value: Availability
  ) => {
    const newAvailability = [...agentAvailabilty];
    newAvailability[index] = value;
    setAgentAvailablity(newAvailability);
  };

  const handleAddAgent = () => {
    setAgents([
      ...agents,
      {
        is_away: false,
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        availability: {},
      },
    ]);
    setAgentAvailablity([...agentAvailabilty, {}]);
  };

  return (
    <Stack
      sx={{ width: "100%" }}
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      {agents.map((agent, index: number) => {
        const divid = `agent-wrapper-${index}`;
        return (
          <div key={index} id={divid} className="w-full">
            <TeamMember
              agent={agent}
              availability={agentAvailabilty[index]}
              showRemove={agents.length > 1}
              expanded={expanded.length > index ? expanded[index] : false}
              onAgentChange={(value) => handleAgentChange(index, value)}
              onAgentRemove={() => handleRemoveAgent(index)}
              onAgentAvailabilityChange={(value) =>
                handleAgentAvailabilityChange(index, value)
              }
              onExpandChange={(e, expand) => {
                onChange(index, e, expand);
                if (expand)
                  setTimeout(() => {
                    document.getElementById(divid)?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 500);
              }}
            />
          </div>
        );
      })}

      <Button
        variant="outlined"
        type="button"
        sx={{ width: { xs: "100%", md: 200 } }}
        onClick={handleAddAgent}
      >
        Add more staff
      </Button>
    </Stack>
  );
}
