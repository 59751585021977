import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RegisterDialog } from "../components/RegisterDialog";
import { CompanySection } from "../components/section/CompanySection";
import { ModelCardSection } from "../components/section/ModelCardSection";
import { TeamAccordianSection } from "../components/section/TeamAccordianSection";
import { VoiceSection } from "../components/section/VoiceSection";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { Agent, Availability, DaysOfWeek } from "../interfaces/Agent";
import { CardItem } from "../interfaces/CardItem";
import OutboundCallForm from "../components/OutboundCallForm";

export default function HomeView() {
  const {
    data: { authToken },
    actions: { expireAt },
  } = useContext(AuthContext);
  const {
    data: { isGuest, company_name, cards, agentAvailabilty, agents, voice },
    actions: { updateUser, setIsGuest, setCards },
  } = useContext(ApiContext);

  const modelAccordianRef = useRef<HTMLDivElement | null>();
  const [modelAccordian, setModelAccordian] = useState(false);
  const teamAccordianRef = useRef<HTMLDivElement | null>();
  const [teamAccordian, setTeamAccordian] = useState(false);
  const [availableAccordian, setAvailableAccordian] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState("");
  const [guestTime, setGuestTime] = useState(0);
  const [isRegOpen, setIsRegOpen] = useState(false);

  const titleCase = (s: string) => {
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  };

  const validatePhone = (phone: string): string | undefined => {
    if (phone.length < 9 || !isValidPhoneNumber(phone, "CA"))
      return `${phone} is not a valid phone number`;
  };

  const validateAvailility = (avail: Availability): string | undefined => {
    const keys = Object.values(DaysOfWeek);
    if (avail) {
      for (let i = 0; i < keys.length; i++) {
        const availDay = avail[keys[i]];
        if (availDay) {
          if (
            (availDay.start && !availDay.end) ||
            (!availDay.start && availDay.end)
          )
            return `Both a start and end time must be provided for ${titleCase(
              keys[i]
            )}`;

          if (
            availDay.start &&
            availDay.end &&
            dayjs(availDay.start, "HH:mm").isAfter(dayjs(availDay.end, "HH:mm"))
          )
            return `${titleCase(keys[i])} start time must be before end time`;
        }
      }
    }
  };

  const validateAgents = (agents: Agent[]): string | undefined => {
    for (const agent of agents) {
      if (!agent.first_name) return "Agent missing first name";
      if (!agent.last_name) return "Agent missing first name";
      if (!agent.phone) return "Agent missing first name";
      const validPhone = validatePhone(agent.phone);
      if (validPhone) return validPhone;
    }
  };

  const validateAvails = (items: Availability[]) => {
    for (const available of items) {
      const validAvail = validateAvailility(available);
      if (validAvail) return validAvail;
    }
  };

  const validateCards = (items: CardItem[]) => {
    let totalBody = "";
    for (const item of items) {
      if (!item.title && item.body) return "Model card missing title";
      if (item.title && !item.body) return "Model card missing body";
      totalBody += item.body;
    }

    const maxLength = 10000;
    if (totalBody.length > maxLength) {
      return `The total length of all instructions must not be longer than ${maxLength} characters`;
    }
  };

  const formatPhoneNumber = (phoneNumber: string) =>
    phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let err;
    e.preventDefault();

    setSuccess("");

    if (company_name === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return setError("Business name field is required.");
    }

    const filteredCards = cards.filter(
      (card) => card.title.length > 0 && card.body.length > 0
    );
    err = validateCards(filteredCards);
    if (err) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return setError(err);
    }

    if (!voice || voice === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return setError("Voice field is required.");
    }

    if (agents.length <= 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return setError("Oops. Please add at least one user for your system");
    }

    err = validateAgents(agents);
    if (err) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return setError(err);
    }

    err = validateAvails(agentAvailabilty);
    if (err) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return setError(err);
    }

    setLoading(true);

    updateUser(
      company_name,
      voice,
      filteredCards,
      agents.map((item, i) => ({ ...item, availability: agentAvailabilty[i] }))
    )
      .then(() => {
        const exp = new Array(agents.length).fill(false);
        setAvailableAccordian(exp);
        setModelAccordian(false);
        setTeamAccordian(false);
        setError("");
        setCards(filteredCards);
        setSuccess(
          "Success! Your changes have been immediately saved and are now live"
        );
      })
      .catch((error) => {
        if (
          error.response?.data?.detail &&
          typeof error.response?.data?.detail === "string"
        ) {
          setError(error.response.data.detail);
        } else {
          setError("An error occured. Please try again.");
        }

        console.error(error.response.data.detail);
      })
      .finally(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setLoading(false);
      });
  };

  const onAvailabilityChange = (
    index: number,
    e: React.SyntheticEvent,
    expand: boolean
  ) => {
    e.stopPropagation();
    const exp = [...availableAccordian];
    while (exp.length < index) {
      exp.push(false);
    }
    exp[index] = expand;
    setAvailableAccordian(exp);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (isGuest) {
      const diff = expireAt(authToken) - Date.now();
      const totalMinutes = Math.max(0, Math.floor(diff / (60 * 1000)));
      setGuestTime(totalMinutes);

      timeout = setTimeout(() => {
        const diff = expireAt(authToken) - Date.now();
        const totalMinutes = Math.max(0, Math.floor(diff / (60 * 1000)));
        setGuestTime(totalMinutes);
      }, 30 * 60 * 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [authToken, expireAt, isGuest]);

  return (
    <Paper className="p-4" elevation={1} sx={{ width: "100%" }}>
      <form id="home-form" ></form>
        <Stack
          direction="column"
          sx={{ maxWidth: 1280, margin: "auto", minHeight: "80vh" }}
          spacing={2}
        >
          {isGuest && (
            <>
              <RegisterDialog
                open={isRegOpen}
                onClose={(reason) => {
                  if (reason) {
                    setIsGuest(false);
                    setSuccess(reason);
                  }
                  setIsRegOpen(false);
                }}
              />
              <Alert
                onClick={() => setIsRegOpen(true)}
                severity="warning"
                sx={{ cursor: "pointer" }}
              >
                {guestTime > 0
                  ? `This account expires in ${guestTime} minutes.`
                  : "This account has expired"}{" "}
                To keep your data active, <u>click here</u> to register for
                free.
              </Alert>
            </>
          )}
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success} </Alert>}
          <Box>
            <Typography variant="h3">
              Your number: {authToken && formatPhoneNumber(authToken?.tn)}
            </Typography>
            {process.env.REACT_APP_OUTBOUND && (<OutboundCallForm />)}
          </Box>

          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            Your business name
          </Typography>

          <Box>
            <CompanySection />
          </Box>

          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            Choose your receptionist
          </Typography>

          <Box>
            <VoiceSection />
          </Box>
          <Stack direction="column" spacing={0}>
            <Accordion
              slotProps={{ transition: { unmountOnExit: true } }}
              disableGutters
              elevation={0}
              square
              expanded={modelAccordian}
              onChange={(e, isExpanded) => {
                setModelAccordian(isExpanded);
                if (isExpanded)
                  setTimeout(() => {
                    modelAccordianRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 500);
              }}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                expandIcon={
                  <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                }
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="h6" sx={{ margin: 0 }}>
                    Model instructions
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box ref={modelAccordianRef} sx={{ width: "100%" }}>
                  <ModelCardSection />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              slotProps={{ transition: { unmountOnExit: true } }}
              disableGutters
              elevation={0}
              square
              expanded={teamAccordian}
              onChange={(e, isExpanded) => {
                setTeamAccordian(isExpanded);
                if (isExpanded)
                  setTimeout(() => {
                    teamAccordianRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 500);
              }}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                expandIcon={
                  <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                }
              >
                <Typography variant="h6" sx={{ margin: 0 }}>
                  List your team
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box ref={teamAccordianRef} sx={{ width: "100%" }}>
                  <TeamAccordianSection
                    expanded={availableAccordian}
                    onChange={onAvailabilityChange}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <Button
              variant="contained"
              onClick={(e) => handleSubmit(e)}
              sx={{ width: { xs: "100%", md: 200 } }}
            >
              Submit
              {loading ? (
                <LoadingCircle />
              ) : (
                <div className="flex items-center justify-center w-3 ml-1">
                  <RightArrow />
                </div>
              )}
            </Button>
          </Stack>
        </Stack>
    </Paper>
  );
}
