import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import { RightArrow } from "../img/RightArrow";
import { Close } from "../img/Close";
import ApiContext from "../context/ApiContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { showFriendlyErrorFromHTMLCode, useMediaMobile } from "../utils/utils";
import * as yup from "yup";
import { IAgentCreate } from "../interfaces/Agent";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IVoiceResult } from "../interfaces/IVoiceResult";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ICityResult } from "../interfaces/ICityResult";

const MIN_SEARCH_LENGTH = 1;
const DEFAULT_CITY: ICityResult = { city: "Toronto", province: "ON" };

function formatCity(city: ICityResult) {
  return city.city + ", " + city.province;
}

interface IFormInput {
  cityId: string;
  businessName: string;
  voice: string;
  agents?: IAgentCreate[];
}
const agentSchema = {
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .test((phone, ctx) => {
      if (phone.length < 9 || !isValidPhoneNumber(phone, "CA"))
        return ctx.createError({
          message: `${phone} is not a valid phone number`,
        });
      return true;
    }),
};

const schema = yup
  .object({
    cityId: yup.string().required("City field is required."),
    businessName: yup.string().required("Business name field is required."),
    voice: yup.string().required("Voice field is required."),
    agents: yup
      .array()
      .min(1, "Oops. Please add at least one user for your system.")
      .of(yup.object().shape(agentSchema)),
  })
  .required();

export default function LandingViewForm() {
  const {
    actions: { createGuest },
  } = useContext(AuthContext);
  const {
    data: { cities: defaultCities },
    actions: { getCities, getVoicesDemo },
  } = useContext(ApiContext);

  const theme = useTheme();
  const isMobile = useMediaMobile();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [inputValue, setInputValue] = useState<string>(
    formatCity(DEFAULT_CITY)
  );
  const [cities, setCities] = useState<ICityResult[]>(defaultCities);
  const [voices, setVoices] = useState<IVoiceResult[]>([]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cityId: cities && cities.length > 0 ? formatCity(cities[0]) : "",
      businessName: "",
      voice:
        voices && voices.length > 0 ? voices[0].name.toLowerCase() : "alice",
      agents: [{ first_name: "", last_name: "", phone: "" }],
    },
  });

  useEffect(() => {
    setCities(defaultCities);
  }, [defaultCities]);

  const fetchCityData = async (input: string) => {
    if (input.length >= MIN_SEARCH_LENGTH) {
      if (!defaultCities.find((value) => formatCity(value) === input)) {
        const prefix = input;
        let c = await getCities(prefix);
        if (c.length <= 0) c = [DEFAULT_CITY];
        setCities(c);
      }
    } else {
      setCities(defaultCities);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const v = await getVoicesDemo();
      setVoices(v);
    }

    fetchData();
  }, [getVoicesDemo]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const cityId = getValues("cityId");
      if (!cityId) {
        const i = cities.findIndex((item) => item.city === DEFAULT_CITY.city);
        setValue("cityId", formatCity(i >= 0 ? DEFAULT_CITY : cities[0]));
        setInputValue(getValues("cityId"));
      }
    }
  }, [cities, getValues, setValue]);

  useEffect(() => {
    if (voices && voices.length) {
      const voice = getValues("voice");
      if (!voice) setValue("voice", voices[0].name.toLowerCase());
    }
  }, [voices, getValues, setValue]);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleAddAgent = () => {
    append({ first_name: "", last_name: "", phone: "" });
  };

  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { cityId, businessName, voice, agents } = data;
    if (!agents || agents?.length <= 0) return;

    setError("");
    setSuccess("");
    setLoading(true);
    createGuest(cityId, businessName, voice, agents)
      .then(() => {
        setError("");
      })
      .catch(() => {
        setError(showFriendlyErrorFromHTMLCode(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper elevation={1}>
      <form
        className="p-10 space-y-5 w-[100%]"
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success} </Alert>}
        <>
          <div className="spasrc/views/LandingView.tsxe-y-2">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6 space-y-2">
                <Typography variant="h6">Your number</Typography>
                <div className="flex items-center justify-center w-full gap-2">
                  <Controller
                    name="cityId"
                    control={control}
                    render={({ field }) => {
                      const e = errors.cityId?.message;
                      return (
                        <FormControl
                          sx={{ width: "100%" }}
                          error={e !== undefined}
                        >
                          <Autocomplete
                            autoHighlight
                            value={field.value || formatCity(DEFAULT_CITY)}
                            onChange={(event: any, newValue: string | null) => {
                              //setCityId(newValue);
                              setValue(
                                "cityId",
                                newValue || formatCity(DEFAULT_CITY)
                              );
                            }}
                            blurOnSelect
                            disableClearable
                            autoComplete
                            loading={loading}
                            filterOptions={(x) => x}
                            options={cities.map((item) => formatCity(item))}
                            inputValue={inputValue}
                            onInputChange={(
                              e: React.SyntheticEvent,
                              newInputValue
                            ) => {
                              fetchCityData(newInputValue);
                              setInputValue(newInputValue);
                            }}
                            onFocus={() => {
                              setCities(defaultCities);
                              setInputValue("");
                            }}
                            renderInput={(params) => <TextField {...params}   placeholder="Start typing your city" InputProps={{
                              ...params.InputProps,
                              sx: {boxShadow:'none'}
                            }} />}
                            sx={{ width: "100%" }}
                          />
                          <FormHelperText>
                            {e ? e.toString() : "　"}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 space-y-2">
                <Typography variant="h6">Receptionist Voice</Typography>
                <div className="flex items-center justify-center w-full gap-2">
                  <Controller
                    name="voice"
                    control={control}
                    render={({ field }) => {
                      const e = errors.voice?.message;
                      return (
                        <FormControl
                          sx={{ width: "100%" }}
                          error={e !== undefined}
                        >
                          <Select
                            {...field}
                            defaultValue={
                              voices && voices.length > 0
                                ? voices[0].name.toLowerCase()
                                : "alice"
                            }
                          >
                            {voices?.map((v) => {
                              return (
                                <MenuItem
                                  key={v.name.toLowerCase()}
                                  value={v.name.toLowerCase()}
                                >
                                  {v.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText>
                            {e ? e.toString() : "　"}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <Typography variant="h6">1. Your business name</Typography>
            <Controller
              name="businessName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.businessName !== undefined}
                  helperText={
                    errors.businessName
                      ? errors.businessName?.message?.toString()
                      : "　"
                  }
                  className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                  type="text"
                  sx={{ width: "100%" }}
                />
              )}
            />
          </div>
          <div className="space-y-2">
            <Typography variant="h6">2. List your team</Typography>
            <>
              {fields.map((agent, index) => (
                <Grid
                  key={agent.id}
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name={`agents.${index}.first_name`}
                      render={({ field }) => {
                        const e =
                          errors?.agents?.at !== undefined &&
                          errors?.agents.at(index) !== undefined &&
                          errors?.agents?.at(index)?.first_name?.message;
                        console.log(e);
                        return (
                          <TextField
                            {...field}
                            error={e !== undefined && e !== false}
                            helperText={e ? e.toString() : "　"}
                            sx={{ width: "100%" }}
                            type="text"
                            placeholder="First name"
                            inputProps={{
                              maxLength: 50,
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name={`agents.${index}.last_name`}
                      render={({ field }) => {
                        const e =
                          errors?.agents?.at !== undefined &&
                          errors?.agents.at(index) !== undefined &&
                          errors?.agents?.at(index)?.last_name?.message;
                        return (
                          <TextField
                            {...field}
                            error={e !== undefined && e !== false}
                            helperText={e ? e.toString() : "　"}
                            sx={{ width: "100%" }}
                            type="text"
                            placeholder="Last name"
                            inputProps={{
                              maxLength: 50,
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={fields.length > 1 ? 3 : 4}>
                    <Controller
                      control={control}
                      name={`agents.${index}.phone`}
                      render={({ field }) => {
                        const e =
                          errors?.agents?.at !== undefined &&
                          errors?.agents.at(index) !== undefined &&
                          errors?.agents?.at(index)?.phone?.message;
                        return (
                          <TextField
                            {...field}
                            error={e !== undefined && e !== false}
                            helperText={e ? e.toString() : "　"}
                            sx={{ width: "100%" }}
                            type="text"
                            placeholder="Phone"
                            inputProps={{
                              maxLength: 50,
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {fields.length > 1 && (
                    <Grid item xs={12} md={1}>
                      {isMobile ? (
                        <IconButton
                          disabled={fields.length <= 1}
                          onClick={() => handleRemoveAgent(index)}
                        >
                          <Close />
                        </IconButton>
                      ) : (
                        <Button
                          sx={{
                            width: "100%",
                            color: theme.palette.error.dark,
                          }}
                          variant="outlined"
                          onClick={() => handleRemoveAgent(index)}
                        >
                          Remove team member
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              ))}
              <div>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onClick={handleAddAgent}
                >
                  Add team member
                </Button>
              </div>
            </>
          </div>
        </>

        <Button variant="contained" sx={{ width: "100%" }} type="submit">
          Create my AI Phone System
          {loading ? (
            <LoadingCircle />
          ) : (
            <div className="flex items-center justify-center w-3 ml-1">
              <RightArrow />
            </div>
          )}
        </Button>
      </form>
    </Paper>
  );
}
