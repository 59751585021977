import { Grid } from "@mui/material";
import ApiContext from "../../context/ApiContext";
import { useContext } from "react";
import { AvatarCard } from "../AvatarCard";

export interface ModelCardProps {}

export function VoiceSection() {
  const {
    data: { voice, voices },
    actions: { setVoice },
  } = useContext(ApiContext);

  return (
    <Grid container direction="row" spacing={2} justifyContent="flex-start">
      {voices &&
        voices.map((v) => {
          return (
            <Grid item key={v.name}>
              <AvatarCard
                name={v.name}
                selected={voice === v.name.toLowerCase()}
                is_beta={v.is_beta}
                is_premium={v.is_premium}
                onClick={() => setVoice(v.name.toLowerCase())}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
