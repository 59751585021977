export interface AvailabilityTime {
  start?: string;
  end?: string;
}

export enum DaysOfWeek {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATUDAY = "saturday",
  SUNDAY = "sunday",
}

export interface Availability {
  monday?: AvailabilityTime;
  tuesday?: AvailabilityTime;
  wednesday?: AvailabilityTime;
  thursday?: AvailabilityTime;
  friday?: AvailabilityTime;
  saturday?: AvailabilityTime;
  sunday?: AvailabilityTime;
}

export interface Agent {
  is_away: boolean | undefined;
  first_name: string;
  last_name: string;
  phone: string;
  email?: string;
  availability: Availability;
}

export interface IAgentCreate {
  first_name: string;
  last_name: string;
  phone: string;
}