import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AddPlus } from "../img/AddPlus";
import { CardItem } from "../interfaces/CardItem";
import { ModelCard } from "./ModelCard";
import ApiContext from "../context/ApiContext";
import { useContext, useState } from "react";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  useSensor,
  useSensors,
  MeasuringStrategy,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableModelCard } from "./SortableModelCard";

import {
  MouseSensor as LibMouseSensor,
  TouchSensor as LibTouchSensor,
} from "@dnd-kit/core";
import { MouseEvent, TouchEvent } from "react";

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static activators = [
    { eventName: "onMouseDown", handler },
  ] as (typeof LibMouseSensor)["activators"];
}

export class TouchSensor extends LibTouchSensor {
  static activators = [
    { eventName: "onTouchStart", handler },
  ] as (typeof LibTouchSensor)["activators"];
}

export function ModelCardLayout() {
  const {
    data: { cards },
    actions: { setCards },
  } = useContext(ApiContext);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    })
  );

  const handleCardChange = (index: number, value: CardItem) => {
    const newCards = [...cards];
    newCards[index] = value;
    setCards(newCards);
  };

  const handleRemoveCard = (index: number) => {
    if (cards.length > 1) {
      const updatedCards = [...cards];
      updatedCards.splice(index, 1);
      setCards(updatedCards);
    }
  };

  const handleAddCards = () => {
    setCards([
      ...cards,
      {
        title: "",
        body: "",
        sort_order: cards.length,
      },
    ]);
  };

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active && over && active?.id !== over?.id) {
      const newItems = arrayMove(cards, active.id, over.id).map(
        (item, index) => ({
          ...item,
          sort_order: index,
        })
      );
      setCards(newItems);
    }
    setActiveId(null);
  };

const buttonDisabled = cards.length > 9

  return (
    <Box sx={{ width: "100%", paddingLeft: 2 }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setActiveId(null)}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start" 
          spacing={2}
          sx={{ width: "100%",  }}
        >
          <SortableContext items={cards.map((item, i) => `${i}`)}>
            {cards.map((item, i) => (
              <Grid item xs={12} sm={6} md={4}>
                <SortableModelCard
                  key={`${i}`}
                  id={`${i}`}
                  modelProps={{
                    card: item,
                    onCardChange: (card) => handleCardChange(i, card),
                    onCardRemove: () => handleRemoveCard(i),
                  }}
                />
              </Grid>
            ))}

            {activeId ? (
              <DragOverlay>
                <ModelCard
                  card={cards[activeId]}
                  onCardChange={() => {}}
                  onCardRemove={() => {}}
                  sx={{
                    cursor: "grabbing",
                    opacity: activeId ? 0.6 : 0,
                    border: "2px dashed grey",
                  }}
                />
              </DragOverlay>
            ) : null}
          </SortableContext>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                backgroundColor: "#F8FAFE",
                cursor: "pointer",
              }}
            >
              <CardContent>
                <Button
                  onClick={handleAddCards}
                  disabled={buttonDisabled}
                  sx={{
                    height: 345,
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <AddPlus color={buttonDisabled ? "#999999" : undefined} />
                    <Typography sx={{ color: buttonDisabled ? "#999999": undefined }}>Add Card</Typography>
                  </Stack>
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DndContext>
    </Box>
  );
}
