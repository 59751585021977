import React, { useCallback, useContext, useEffect, useState } from "react";
import { Agent, Availability } from "../interfaces/Agent";
import { AxiosResponse } from "axios";
import AuthContext from "./AuthContext";
import { default as ApiService } from "../context/ApiServices";
import { IGetUserResult } from "../interfaces/IGetUserResult";
import { IVoiceResult } from "../interfaces/IVoiceResult";
import { CardItem } from "../interfaces/CardItem";
import { ICityResult } from "../interfaces/ICityResult";

const ApiContext = React.createContext<{
  actions: {
    getUser: () => Promise<IGetUserResult>;
    updateUser: (
      businessName: string,
      voice: string,
      cards: CardItem[],
      agents: Agent[]
    ) => Promise<AxiosResponse<{ result: any }> | undefined>;
    getVoicesDemo: () => Promise<IVoiceResult[]>;
    getVoices: () => Promise<IVoiceResult[]>;
    getCities: (prefix: string) => Promise<ICityResult[]>;
    setVoice: (_: string) => void;
    setCompanyName: (_: string) => void;
    setIsGuest: (_: boolean) => void;
    setAgents: (_: Agent[]) => void;
    setAgentAvailablity: (_: Availability[]) => void;
    setCards: (_: CardItem[]) => void;
    requestOutboundCall: (phone: string) => Promise<any>;
  };
  data: {
    voice: string;
    company_name: string;
    isGuest: boolean;
    agents: Agent[];
    agentAvailabilty: Availability[];
    cards: CardItem[];
    defaultCards: CardItem[];

    voices: IVoiceResult[];
    cities: ICityResult[];
  };
}>({
  actions: {
    getUser: async () => ({
      is_guest: false,
      company_name: "",
      cards: [],
      voice: "",
      users: [],
    }),
    updateUser: async () => undefined,
    getVoicesDemo: async () => [],
    getVoices: async () => [],
    getCities: async () => [],
    setVoice: () => {},
    setCompanyName: () => {},
    setIsGuest: () => {},
    setAgents: () => {},
    setAgentAvailablity: () => {},
    setCards: () => {},
    requestOutboundCall: async (phone: string) => {},
  },
  data: {
    voice: "",
    company_name: "",
    isGuest: true,
    agents: [],
    agentAvailabilty: [],
    cards: [],
    defaultCards: [],
    voices: [],
    cities: [],
  },
});

interface ApiContextProviderProps {
  children: React.ReactNode;
}

export function ApiContextProvider(
  props: ApiContextProviderProps
): React.ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const [voice, setVoice] = useState<string>("");
  const [company_name, setCompanyName] = useState("");
  const [isGuest, setIsGuest] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentAvailabilty, setAgentAvailablity] = useState<Availability[]>([]);
  const [cards, setCards] = useState<CardItem[]>([]);

  const [voices, setVoices] = useState<IVoiceResult[]>([]);
  const [cities, setCities] = useState<ICityResult[]>([]);

  const [defaultCards, setDefaultCards] = useState<CardItem[]>([]);

  const setUserResult = (data: IGetUserResult) => {
    setVoice(data.voice);
    setCompanyName(data.company_name);
    setIsGuest(data.is_guest);
    setAgents(data.users);
    setAgentAvailablity(data.users.map((agent) => agent.availability));
    setCards(
      data.cards
        .map((card) => ({ ...card }))
        .sort((a, b) => a.sort_order - b.sort_order)
    );
  };

  const getUser = useCallback(() => {
    return new Promise<IGetUserResult>(async (resolve, reject) => {
      if (!isLoggedIn()) {
        reject("You are not logged in. Please log in and try again");
      }
      try {
        const response = await ApiService.api.get(`/user/${authToken?.tn}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${authToken?.access_token}`,
          },
        });

        const result = {
          ...response.data.data,
          is_guest: response.data.is_guest,
        };
        setUserResult(result);
        resolve(result);
      } catch (e: any) {
        reject(e);
      }
    });
  }, [authToken, isLoggedIn]);

  const updateUser = useCallback(
    (
      businessName: string,
      voice: string,
      cards: CardItem[],
      agents: Agent[]
    ) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = ApiService.api.put(
            `user/${authToken!.tn}`,
            {
              company_name: businessName,
              voice: voice,
              cards: cards,
              users: agents,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken?.access_token}`,
              },
            }
          );
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const getVoicesDemo = useCallback(() => {
    return new Promise<IVoiceResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`voices/demo`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const voices: IVoiceResult[] = response.data;
        const sorted = voices.sort((a, b) => {
          if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
          return a.is_premium && !b.is_premium ? 1 : -1;
        });
        setVoices(sorted);
        resolve(sorted);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getVoices = useCallback(() => {
    return new Promise<IVoiceResult[]>(async (resolve, reject) => {
      if (!isLoggedIn()) {
        reject("You are not logged in. Please log in and try again");
      }
      try {
        const response = await ApiService.api.get(`voices`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken?.access_token}`,
          },
        });

        const voices: IVoiceResult[] = response.data;
        const sorted = voices.sort((a, b) => {
          if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
          return a.is_premium && !b.is_premium ? 1 : -1;
        });
        setVoices(sorted);
        resolve(sorted);
      } catch (e: any) {
        reject(e);
      }
    });
  }, [authToken, isLoggedIn]);

  const getCities = useCallback((prefix?: string) => {
    return new Promise<ICityResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`cities`, {
          params: {
            prefix: prefix ? prefix : undefined,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        const cities: ICityResult[] = response.data;
        const sorted = cities.sort((a, b) => {
          if (a.province === b.province) return a.city > b.city ? 1 : -1;
          return a.province > b.province ? 1 : -1;
        });

        resolve(sorted);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getDefaultCards = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`default_context`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setDefaultCards(response.data.cards);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const requestOutboundCall = useCallback((phone: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.post(
          `outbound-call`,
          { to_phone: phone },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken?.access_token}`,
            },
          }
        );
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, [authToken]);

  useEffect(() => {
    async function fetchData() {
      try {
        const v = await getVoices();

        setVoices(v);
        setVoice(v[0].name.toLowerCase());
      } catch (e) {
        const v = await getVoicesDemo();
        setVoices(v);
        setVoice(v[0].name.toLowerCase());
      }
    }

    if (isLoggedIn()) fetchData();
  }, [getVoices, getVoicesDemo, isLoggedIn]);

  useEffect(() => {
    async function fetchData() {
      setCities(await getCities(""));
    }
    fetchData();
  }, [getCities]);

  useEffect(() => {
    if (isLoggedIn()) getUser();
  }, [authToken, getUser, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn()) getDefaultCards();
  }, [authToken, getDefaultCards, isLoggedIn]);

  return (
    <ApiContext.Provider
      value={{
        actions: {
          getUser,
          updateUser,
          getVoicesDemo,
          getVoices,
          getCities,
          setVoice,
          setCompanyName,
          setIsGuest,
          setAgents,
          setAgentAvailablity,
          setCards,
          requestOutboundCall,
        },
        data: {
          voice,
          company_name,
          isGuest,
          agents,
          agentAvailabilty,
          cards,
          defaultCards,
          voices,
          cities,
        },
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
export default ApiContext;
