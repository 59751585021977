import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Availability, DaysOfWeek } from "../interfaces/Agent";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

export interface AvailabilityAccordianProps {
  expanded: boolean;
  available?: Availability;

  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
  onStartChange?: (day: DaysOfWeek, time?: Dayjs | null) => void;
  onEndChange?: (day: DaysOfWeek, time?: Dayjs | null) => void;
}

export default function AvailabilityAccordian(
  props: AvailabilityAccordianProps
) {
  const { expanded, available, onChange, onStartChange, onEndChange } = props;
  const timePickerProps = {};

  const titleCase = (s: string) => {
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  };

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true } }}
      disableGutters elevation={0} square 
      expanded={expanded === true}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      >
        <Typography>Availability</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {Object.values(DaysOfWeek).map((day) => (
            <Grid
              key={day}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
              spacing={2}
            >
              <Grid item xs={4}>
                <Typography>{titleCase(day)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  sx={timePickerProps}
                  label="From"
                  value={
                    available && available[day]?.start
                      ? dayjs(available[day]?.start, "HH mm")
                      : null
                  }
                  onChange={(e) => {
                    if (onStartChange) onStartChange(day, e);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  sx={timePickerProps}
                  label="To"
                  value={
                    available && available[day]?.end
                      ? dayjs(available[day]?.end, "HH mm")
                      : null
                  }
                  onChange={(e) => {
                    if (onEndChange) onEndChange(day, e);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
