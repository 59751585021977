import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../context/AuthContext";
import { HideIcon } from "../img/HideIcon";
import { LoadingCircle } from "../img/LoadingCircle";
import { Logo } from "../img/Logo";
import { RightArrow } from "../img/RightArrow";
import { ShowIcon } from "../img/ShowIcon";
import { theme } from "../theme";
import PlainContainer from "../components/container/PlainContainer";
import { showFriendlyErrorFromHTMLCode } from "../utils/utils";

interface IFormInput {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    password: yup.string().required("Password field is required.").max(50),
  })
  .required();

export default function SigninView() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    actions: { login },
  } = useContext(AuthContext);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, password } = data;
    setError("");
    setLoading(true);
    login(email, password)
      .then((response) => {
        setError("");
        navigate("/home");
      })
      .catch((error) => {
        setError(showFriendlyErrorFromHTMLCode(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PlainContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h3">Sign in</Typography>
          <Typography variant="h6">Email</Typography>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.email !== undefined}
                helperText={errors.email ? errors.email?.message : "　"}
                className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                type="text"
              />
            )}
          />

          <Typography variant="h6">Password</Typography>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.password !== undefined}
                helperText={errors.password ? errors.password?.message : "　"}
                className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                type={passwordShown ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {!passwordShown ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Link href="/forgot-password">Forgot Password?</Link>
          </Stack>
          <Button sx={{ width: "100%" }} variant="contained" type="submit">
            Sign in {loading ? <LoadingCircle /> : <RightArrow />}
          </Button>
          <div className="grid text-sm text-muted-foreground">
            <p>
              Not registered? <Link href="/">Click here</Link> to Sign up
            </p>
          </div>
        </Stack>
      </form>
    </PlainContainer>
  );
}
