import { useMediaQuery, useTheme } from "@mui/material";

export const showFriendlyErrorFromHTMLCode = (error: any): string => {
  if (error.response?.data?.detail.length > 0) {
    if (error.response?.data?.detail[0].msg)
      return error.response?.data?.detail[0].msg;
  }
  if (error.response?.data?.detail) return error.response?.data?.detail;
  return "An error occured. Please try again.";
};

export function useMediaMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
}
