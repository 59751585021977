import "./App.css";
import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingView from "./views/LandingView";
import SigninView from "./views/SigninView";
import AppLayout from "./layouts/AppLayout";
import HomeView from "./views/HomeView";
import PublicRoutes from "./utils/PublicRoutes";
import AuthLayout from "./layouts/AuthLayout";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AuthContext, { AuthContextProvider } from "./context/AuthContext";
import { ApiContextProvider } from "./context/ApiContext";
import { ThemeProvider } from "@mui/material";
import { innerTheme, theme } from "./theme";
import ForgotPasswordView from "./views/ForgotPasswordView";
import ResetPasswordView from "./views/ResetPasswordView";

function HomeViewWrapper() {
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  if (isLoggedIn())
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HomeView />
      </LocalizationProvider>
    );
  if (authToken !== undefined) return <LandingView />;
}

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <ApiContextProvider>
          <ThemeProvider theme={theme}>
            <ThemeProvider theme={innerTheme}>
              <Routes>
                <Route element={<AppLayout />}>
                  <Route path="/" element={<HomeViewWrapper />} />
                  <Route
                    path="/home"
                    element={<Navigate to="/" replace={true} />}
                  />
                </Route>

                <Route element={<PublicRoutes />}>
                  <Route element={<AuthLayout />}>
                    <Route path="/signin" element={<SigninView />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordView />}
                    />
                    <Route
                      path="/reset-password"
                      element={<ResetPasswordView />}
                    />
                  </Route>
                </Route>
              </Routes>
            </ThemeProvider>
          </ThemeProvider>
        </ApiContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
