import { Box, Button, Stack } from "@mui/material";
import ApiContext from "../../context/ApiContext";
import { useContext } from "react";
import { ModelCardLayout } from "../ModelCardLayout";

export interface ModelCardProps {}

export function ModelCardSection(props: ModelCardProps) {
  const {
    data: { defaultCards },
    actions: { setCards },
  } = useContext(ApiContext);

  const handleReset = () => {
    setCards(defaultCards);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <ModelCardLayout />
      <Box sx={{ paddingLeft: 2 }}>
        <Button variant="outlined" type="button" onClick={handleReset}>
          Reset
        </Button>
      </Box>
    </Stack>
  );
}
