import { TextField } from "@mui/material";
import ApiContext from "../../context/ApiContext";
import { useContext } from "react";

export interface ModelCardProps {}

export function CompanySection() {
  const {
    data: { company_name },
    actions: { setCompanyName },
  } = useContext(ApiContext);

  return (
    <TextField
      onChange={(e) => {
        setCompanyName(e.target.value);
      }}
      value={company_name}
      className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
      type="text"
      inputProps={{
        maxLength: 50,
      }}
    />
  );
}
